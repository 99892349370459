.hero.is-link {
    background-color: transparent;
}

.box {
    background-color: transparent;
}

#rankings-table {
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    color: white;
    width: 100%;
    word-break: break-all;
}

#rankings-table colgroup col:nth-child(1){
    width: 10%;
}

#rankings-table colgroup col:nth-child(2){
    width: 25%;
}

#rankings-table th {
    color: white;
    text-align: center;
}

#rankings-table td {
    vertical-align: middle;
    text-align: center;
}

#rankings-table td figure {
    display: block;
    margin: 0 auto;
}

#rankings-table .player-name {
    text-align: left;
}

#placeholder-table-while-rankings-are-loading {
    height: 100vh;
}


.megastriker {
    color: orange !important;
}

.legend {
    color: purple !important;
}

.superstar {
    color: #4da6ff !important;
}

.professional {
    color:#cccc00 !important;
}

.rookie {
    color: green !important;
}
