.hero.is-link {
    background-color: transparent;
}

.box {
    background-color: transparent;
}

#clubs-table {
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    color: white;
}

#clubs-table td {
    vertical-align: middle;
    text-align: center;
}

#clubs-table td img {
    height: 100%;
}

#clubs-table th {
    color: white;
    text-align: center;
}

#clubs-table tr:hover {
    background-color: hsla(132, 6%, 83%, 0.3);

}

#placeholder-table-while-clubs-are-loading {
    height: 100vh;
}

.clickable-club {
    color: white;
}
