.box {
    background-color: transparent;
}

#mslrankings-table {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: transparent;
    color: white;
    width: 100%;
    word-break: break-all;
}

#mslrankings-table tr td:nth-child(2){
    text-align: left;
}

#mslrankings-table colgroup col:nth-child(1){
    width: 10%;
}

#mslrankings-table colgroup col:nth-child(2){
    width: 25%;
}

#mslrankings-table tr th:nth-child(2){
    text-align: left;
}

#ranking-row-header > th {
    text-transform: capitalize;
    color: white;
    text-align: center;
}

#placeholder-table-while-mslrankings-are-loading {
    height: 100vh;
}

#column-for-mslrankings-table {
    min-height: 100vh;
}
