body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.bg-img {
    background-image: url(./assets/landing_page_bg2.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.image img {
    margin: auto;
    display: block;
    max-height: 200px;
    height: 70%;
    width: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollbar {
  /*margin-left: 30px;*/
  float: left;
  height: 300px;
  background: #fff;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.scrollbar-near-moon {
scrollbar-color: #b490ca #f5f5f5;
}

.scrollbar-near-moon::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
background-color: #F5F5F5;
border-radius: 10px; }

.scrollbar-near-moon::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }

.scrollbar-near-moon::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-image: -webkit-gradient(linear, left bottom, left top, from(#5ee7df), to(#b490ca));
      background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
      background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%); }

/* ANIMATIONS */
.textanimation-enter {
  opacity: 0;
  transform: scale(0.9);
}
.textanimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 700ms, transform 700ms;
}
.textanimation-exit {
  opacity: 1;
}
.textanimation-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 700ms, transform 700ms;
}
